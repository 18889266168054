import HttpClient from '@/utils/http'

export default {
    // 分页查询直播列表（教师端）
    queryTeacherLiveList(data) {
        return HttpClient.get(`/cloud-livestream/live/queryTeacherLiveList`, data)
    },
    // 分页查询课程列表
    getCourseList(data) {
        return HttpClient.get(`/cloud-livestream/course/queryCourseList`, data)
    }
}