<template>
    <baseContainer>
        <template v-slot:heads>
            <HeadNav/>
        </template>
        <van-pull-refresh class="myTeCourse" v-model="refreshing" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad">
                <div class="custom_search_layout">
                    <van-search
                            class="custom_search_01"
                            v-model="listQuery.name"
                            show-action
                            background="transparent"
                            placeholder="请输入课程名称"
                            @search="onSearch">
                        <template #action>
                            <div @click="onSearch">搜索</div>
                        </template>
                    </van-search>
                </div>
                <div class="myTeLive_content">
                    <BarList :type="pageType.course" class="spaces"
                             :list="list"
                             :propType="propType"
                             @callMore="(item)=>{callMore(item, '/courseDetails')}"/>
                </div>
            </van-list>
        </van-pull-refresh>
    </baseContainer>
</template>

<script>
import HeadNav from '../../components/headNav';
import BarList from './../home/components/BarList';
import DropDownRefresh from './../../mixin/DropDownRefresh';
import constant from './../../utils/constant';
import api from './../../api/teacher';

export default {
    name: "myTeCourse",
    components: {
        HeadNav,
        BarList
    },
    mixins: [DropDownRefresh],
    data () {
        return {
            pageType: constant.pageType,
            listQuery: {
                name: '',
                current: 1,
                size: 10,
                noCorp: true
            },
            propType: {
                img: 'courseCover',
                name: 'name',
                date: 'startTime',
                user: 'teacherName',
                status: 'status'
            }
        }
    },
    methods: {
        callMore (item, url) {
            this.$utils.callMores(item, url, constant.checkLive.noCheck);
        },
        // 分页查询课程列表
        getList () {
            api.getCourseList(this.listQuery).then(res => {
                if (res) {
                    this.onEnd(res.total, res.records);
                }
            });
        }
    }
}
</script>

<style scoped lang="less">
    .myTeCourse {
        background: #F7F7F7;
        min-height: 100%;
        .custom_search_layout {
            padding: 20px 40px 20px;
            box-shadow: 0 4px 8px 0 rgba(233, 233, 233, 0.5);
        }
        .myTeLive_content {
            padding: 10px 40px 40px;
        }
    }
</style>